
import Vue from "vue";
import VueMarkdown from "vue-markdown";
import config from "@/config";
import {download} from "@/utils";

export default Vue.extend({
  name: "Material",
  components: {
    VueMarkdown // documentation: https://www.npmjs.com/package/vue-markdown
  },
  data() {
    return {
      material: "",
      title: "вавава"
    };
  },
  head: {
    title(): any {
      return {
        inner: this.title
      };
    },
  },
  methods: {
    postProcess(renderedHTML: string = ""): void {
      this.addLinksListener();
      this.title = (renderedHTML.match(/<h\d>((?:.(?!=<\/h\d))*)<\/h\d/) || [])[1] || "";
      this.$emit("updateHead");
    },
    addLinksListener(): void {
      setTimeout(() => {
        const material: HTMLDivElement | null = document.querySelector(".material");
        if (material) {
          const links: HTMLCollectionOf<HTMLAnchorElement> = material.getElementsByTagName("a");
          // @tslint-disable
          for (let i = 0; i < links.length; i++) {
            const linkElement: HTMLAnchorElement = links[i];
            const filename = (linkElement.href.match("https?://.*download/(.*)") || [])[1];
            if (filename) {
              linkElement.onclick = async (e: Event) => {
                e.preventDefault();
                const {data: {data: {link}}} = await this.$store.dispatch("materials/getFile", {
                  ...this.$route.query,
                  ...this.$route.params,
                  filename
                });
                download(link, filename.split("/").pop() + "");
                return false;
              };
            }
          }
        }
      }, 100);
    }
  },
  async mounted() {
    const contentUrl: string = config.baseURL + config.endpoints.material;
    this.material = (await this.$store.dispatch("materials/getFile", {...this.$route.params, ...this.$route.query}))
      // replace all markdown images from ![whatever](image_path) to ![whatever](URL/?filename=encoded_image_path&level=number)
      .replace(/!\[([^\]]+)\]\(([^)]+)\)/g, (_: any, p1: string, p2: string) => `![${p1}](${contentUrl}?filename=${encodeURIComponent(p2)}&level=${this.$route.params.level || this.$route.query.level || 1})`)
      // replace markdown links from [whatever](downloads_link) to [whatever](download/downloads_link)
      .replace(/(?:[^!])\[([^\]]+)\]\((downloads\/[^)]+)\)/g, (_: any, p1: string, p2: string) => `[${p1}](download/${p2})`);
  }
});
